import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Observable } from "rxjs";
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger, MatAutocomplete } from "@angular/material/autocomplete";
import { User } from "../../../interfaces/users/user";
import { MitpClientService } from "../../../mitp-client.service";
import { map, startWith } from "rxjs/operators";
import { UntypedFormControl, ReactiveFormsModule, FormsModule } from "@angular/forms";
import { OrderByPipe } from "ngx-pipes";
import { MitpManagerService } from "../../../services/mitp-manager.service";
import { ToastrService } from "ngx-toastr";
import { UserService } from "../../../services/user.service";
import * as fuzz from "fuzzball";
import { MatMiniFabButton } from "@angular/material/button";
import { MatDivider } from "@angular/material/divider";
import { MatIcon } from "@angular/material/icon";
import { MatOption } from "@angular/material/core";
import { MatInput } from "@angular/material/input";
import { MatFormField, MatLabel, MatPrefix } from "@angular/material/form-field";
import { NgIf, NgFor, AsyncPipe } from "@angular/common";
import { MatRadioGroup, MatRadioButton } from "@angular/material/radio";
import { NgxTolgeeModule, TranslateService } from "@tolgee/ngx";

@Component({
	selector: "app-authors-filter",
	templateUrl: "./authors-filter.component.html",
	styleUrls: ["./authors-filter.component.scss"],
	standalone: true,
	imports: [
		MatRadioGroup,
		ReactiveFormsModule,
		FormsModule,
		MatRadioButton,
		NgIf,
		MatFormField,
		MatLabel,
		MatInput,
		MatAutocompleteTrigger,
		MatAutocomplete,
		MatOption,
		MatIcon,
		MatDivider,
		MatPrefix,
		MatMiniFabButton,
		NgFor,
		AsyncPipe,
		NgxTolgeeModule,
	],
})
export class AuthorsFilterComponent implements OnInit {
	@Input() isOutline: boolean = true;
	@Input() placeholder: string = "";
	@Input() authors: User[] = [];
	@Output() authorsChange = new EventEmitter<User[]>();
	@Input() approvers: User[] = [];
	@Output() approversChange = new EventEmitter<User[]>();
	@Input() managers: User[] = [];
	@Output() managersChange = new EventEmitter<User[]>();
	@Input() protocolId: number = -1;
	@Input() modeCreate = false;

	category: number = 2;
	filteredUsers: Observable<User[]>;
	userCtrl = new UntypedFormControl();
	users_all: User[] = [];
	users: User[] = [];
	onlineMode: boolean = false;
	categoryLabels = ["", "managers", "authors", "approvers"];

	constructor(
		private http: MitpClientService,
		private userService: UserService,
		private manager: MitpManagerService,
		private toastr: ToastrService,
		private oPipe: OrderByPipe,
		private translator: TranslateService
	) {}

	ngOnInit(): void {
		this.get();
	}

	get() {
		this.http.getAvailableAuthors().subscribe((users) => {
			this.users_all = this.oPipe.transform(users, ["firstname", "lastname"]);
			this.filteredUsers = this.userCtrl.valueChanges.pipe(
				startWith(""),
				map((value: string | null) => (value ? this._filter(value) : this.users_all.slice()))
			);
			this.loadAuthors();
		});
	}

	selected($event: MatAutocompleteSelectedEvent) {
		if (this.protocolId == -1) {
			this.selectedAction($event.option.value);
		} else {
			this.http
				.addProtocolAuthor(this.protocolId, $event.option.value.id, this.categoryLabels[this.category])
				.subscribe((response) => {
					if (response.status == 204) {
						this.selectedAction($event.option.value);
						this.toastr.success(this.translator.instant("protocols.authors.add.success"));
					} else {
						this.toastr.error(this.translator.instant("core.message.error.unknown"));
					}
				});
		}
	}

	loadAuthors() {
		switch (this.category) {
			case 1:
				this.users = this.managers;
				break;
			case 2:
				this.users = this.authors;
				break;
			case 3:
				this.users = this.approvers;
				break;
		}
	}

	isDisabled(user: User): boolean {
		switch (this.category) {
			case 1:
				return this.managers.map((e) => e.id).includes(user.id);
			case 2:
				return (
					this.approvers.map((e) => e.id).includes(user.id) || this.authors.map((e) => e.id).includes(user.id)
				);
			case 3:
				return (
					this.authors.map((e) => e.id).includes(user.id) || this.approvers.map((e) => e.id).includes(user.id)
				);
			default:
				return false;
		}
	}

	deleteUser(item: User) {
		if (this.protocolId == -1) {
			this.deleteUserAction(item);
		} else {
			this.http
				.deleteProtocolAuthor(this.protocolId, item.id, this.categoryLabels[this.category])
				.subscribe((response) => {
					if (response.status == 204) {
						this.toastr.success(this.translator.instant("protocols.authors.delete.success"));
						this.deleteUserAction(item);
					} else {
						this.toastr.error(this.translator.instant("core.message.error.unknown"));
					}
				});
		}
	}

	getAuthorWarning(user: User): string {
		switch (this.category) {
			case 1:
				return this.translator.instant("protocols.authors.warning.already-manager");
			case 2:
			case 3:
				if (this.approvers.map((e) => e.id).includes(user.id)) {
					return this.translator.instant("protocols.authors.warning.already-approver");
				} else {
					return this.translator.instant("protocols.authors.warning.already-writer");
				}
		}
	}

	isAuthor(user: User): boolean {
		return this.authors.map((e) => e.id).includes(user.id);
	}

	isApprover(user: User): boolean {
		return this.approvers.map((e) => e.id).includes(user.id);
	}

	isProtocolManager(user: User): boolean {
		//TODO merge with isManager() and isMeManager()
		return this.managers.map((e) => e.id).includes(user.id);
	}

	searchUser() {
		let email = this.userCtrl.value;
		if (email == null) {
			this.toastr.error(this.translator.instant("protocols.authors.search-email.error.enter-person-email"));
		} else {
			email = email.trim();
			if (MitpManagerService.isEmailAddress(email)) {
				this.http.getUserByEmail(email).subscribe((user) => {
					this.http
						.addProtocolAuthor(this.protocolId, user.id, this.categoryLabels[this.category])
						.subscribe((response) => {
							if (response.status == 204) {
								this.selectedAction(user);
								this.toastr.success(this.translator.instant("protocols.authors.add.success"));
							} else {
								this.toastr.error(this.translator.instant("core.message.error.unknown"));
							}
						});
				});
			} else {
				this.toastr.error(this.translator.instant("protocols.authors.search-email.error.enter-valid-email"));
			}
		}
	}

	isMeManager(user: User): boolean {
		return this.category == 1 && user.id == this.userService.getUserId();
	}

	isManager(): boolean {
		if (this.modeCreate) {
			return this.manager.isManager();
		} else {
			return this.managers.map((e) => e.id).includes(this.userService.getUserId());
		}
	}

	private selectedAction(user: User) {
		switch (this.category) {
			case 1:
				this.managers.push(user);
				this.managersChange.emit(this.managers);
				break;
			case 2:
				this.authors.push(user);
				this.authorsChange.emit(this.authors);
				break;
			case 3:
				this.approvers.push(user);
				this.approversChange.emit(this.approvers);
				break;
		}
		this.userCtrl.setValue(null);
		this.loadAuthors();
	}

	private _filter(value: string) {
		if (typeof value === "string") {
			const filterValue = value.trim().toLowerCase();
			const resultUsers = this.users_all.filter((it) => {
				return (
					fuzz.partial_ratio(`${it.firstname} ${it.lastname}`.toLowerCase(), filterValue) > 90 ||
					fuzz.partial_ratio(`${it.lastname} ${it.firstname}`.toLowerCase(), filterValue) > 90
				);
			});
			return this.oPipe.transform(resultUsers, "firstname");
		} else {
			return this.users_all.slice();
		}
	}

	private deleteUserAction(user: User) {
		let index = -1;
		switch (this.category) {
			case 1:
				index = this.managers.map((e) => e.id).indexOf(user.id);
				if (index > -1) this.managers.splice(index, 1);
				return;
			case 2:
				index = this.authors.map((e) => e.id).indexOf(user.id);
				if (index > -1) this.authors.splice(index, 1);
				return;
			case 3:
				index = this.approvers.map((e) => e.id).indexOf(user.id);
				if (index > -1) this.approvers.splice(index, 1);
				return;
		}
		this.loadAuthors();
	}
}
