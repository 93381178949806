import { Component, Inject } from "@angular/core";
import {
	MAT_DIALOG_DATA,
	MatDialogRef,
	MatDialogContent,
	MatDialogActions,
	MatDialogClose,
} from "@angular/material/dialog";
import { Protocol } from "../../../interfaces/protocols/protocol";
import { MitpClientService } from "../../../mitp-client.service";
import { ToastrService } from "ngx-toastr";
import { UserService } from "../../../services/user.service";
import { MatProgressSpinner } from "@angular/material/progress-spinner";
import { MatButton } from "@angular/material/button";
import { MatIcon } from "@angular/material/icon";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { MatCheckbox } from "@angular/material/checkbox";
import { NgFor, NgIf } from "@angular/common";
import { NgxTolgeeModule, TranslateService } from "@tolgee/ngx";

@Component({
	selector: "app-protocol-approvers-notify",
	templateUrl: "./protocol-approvers-notify.component.html",
	styleUrls: ["./protocol-approvers-notify.component.scss"],
	standalone: true,
	imports: [
		MatDialogContent,
		NgFor,
		MatCheckbox,
		ReactiveFormsModule,
		FormsModule,
		NgIf,
		MatIcon,
		MatDialogActions,
		MatButton,
		MatDialogClose,
		MatProgressSpinner,
		NgxTolgeeModule,
	],
})
export class ProtocolApproversNotifyComponent {
	isLoading: boolean = false;

	constructor(
		@Inject(MAT_DIALOG_DATA) public protocol: Protocol,
		private http: MitpClientService,
		private userService: UserService,
		private dialogRef: MatDialogRef<ProtocolApproversNotifyComponent>,
		private toastr: ToastrService,
		private translate: TranslateService
	) {}

	isApprover(): boolean {
		return this.protocol.approvers.map((e) => e.id).includes(this.userService.getUserId());
	}

	apply() {
		this.isLoading = true;
		this.http
			.notifyApprovers(
				this.protocol.id,
				this.protocol.approvers.filter((e) => e["send"])
			)
			.subscribe({
				next: (response) => {
					this.isLoading = false;
					if (response.status == 204) {
						this.toastr.success(this.translate.instant("protocol.approvers.notify.success"));
						this.dialogRef.close();
					} else {
						this.toastr.error(this.translate.instant("core.message.error.unknown"));
					}
				},
				error: () => {
					this.isLoading = false;
				},
			});
	}

	enableSend(): boolean {
		return this.protocol.approvers.filter((e) => e["send"]).length > 0;
	}
}
