import { Component } from "@angular/core";
import { MatIcon } from "@angular/material/icon";
import { MatIconButton } from "@angular/material/button";
import { MatMenu, MatMenuItem, MatMenuTrigger } from "@angular/material/menu";
import { MatDivider } from "@angular/material/divider";
import { AuthService } from "../../../auth.service";
import { Router } from "@angular/router";
import { NgxTolgeeModule } from "@tolgee/ngx";

@Component({
	selector: "app-user-badge",
	standalone: true,
	imports: [MatIcon, MatIconButton, MatMenu, MatMenuTrigger, MatMenuItem, MatDivider, NgxTolgeeModule],
	templateUrl: "./user-badge.component.html",
	styleUrl: "./user-badge.component.scss",
})
export class UserBadgeComponent {
	constructor(
		public authService: AuthService,
		private router: Router
	) {}

	navigateToAbout(): void {
		this.router.navigate(["misc/about"]);
	}

	navigateToPreferences(): void {
		this.router.navigate(["settings-user"]);
	}
}
