<div
	class="flex flex-col gap-y-4"
	style="padding: 16px">
	<div class="flex flex-row gap-x-4">
		<mat-card
			appearance="outlined"
			class="flex-1">
			<mat-card-title>
				<div class="flex flex-row items-center justify-center">
					<mat-icon>assignment_ind</mat-icon>
				</div>
			</mat-card-title>
			<mat-card-content class="flex flex-col">
				<p></p>
				<div class="flex flex-row gap-x-2">
					<mat-form-field
						appearance="outline"
						class="flex-1">
						<mat-label>{{ "SETTINGS-USER_FIRST_NAME" | translate }}</mat-label>
						<input
							matInput
							[(ngModel)]="user.firstname"
							[disabled]="true" />
					</mat-form-field>
					<mat-form-field
						appearance="outline"
						class="flex-1">
						<mat-label>{{ "SETTINGS-USER_LAST_NAME" | translate }}</mat-label>
						<input
							matInput
							[(ngModel)]="user.lastname"
							[disabled]="true" />
					</mat-form-field>
				</div>
				<div class="flex flex-row gap-x-2">
					<mat-form-field
						appearance="outline"
						class="flex-1">
						<mat-label>{{ "SETTINGS-USER_RPPS/PHONE" | translate }}</mat-label>
						<input
							matInput
							[(ngModel)]="user.rpps"
							[disabled]="true" />
					</mat-form-field>
					<mat-form-field
						appearance="outline"
						class="flex-1">
						<mat-label>{{ "SETTINGS-USER_MAIL_ADDRESS" | translate }}</mat-label>
						<input
							matInput
							[(ngModel)]="user.email"
							[disabled]="true" />
					</mat-form-field>
				</div>
				<div class="flex flex-row gap-x-2">
					<mat-form-field
						appearance="outline"
						class="flex-1">
						<mat-label>{{ "SETTINGS-USER_FUNCTION" | translate }}</mat-label>
						<input
							matInput
							[(ngModel)]="user.job.label"
							[disabled]="true" />
					</mat-form-field>
					<mat-form-field
						appearance="outline"
						class="flex-1">
						<mat-label>{{ "SETTINGS-USER_ESTABLISHMENT" | translate }}</mat-label>
						<input
							matInput
							[(ngModel)]="user.institute.label"
							[disabled]="true" />
					</mat-form-field>
				</div>
				<!--				<div *ngIf="user?.departments.length > 0">-->
				<!--					<mat-form-field appearance="outline" class="w-full">-->
				<!--						<mat-label>{{'SETTINGS-USER_DEPARTMENTS'| translate}}</mat-label>-->
				<!--						<mat-chip-listbox>-->
				<!--							<mat-chip-option-->
				<!--								*ngFor="let dpt of user?.departments">-->
				<!--								{{dpt.label}}-->
				<!--							</mat-chip-option>-->
				<!--						</mat-chip-listbox>-->
				<!--					</mat-form-field>-->
				<!--				</div>-->
				<hr />
			</mat-card-content>
		</mat-card>

		<mat-card
			appearance="outlined"
			class="flex-1">
			<mat-card-title>
				<div class="flex flex-row items-center justify-center">
					<mat-icon>password</mat-icon>
				</div>
			</mat-card-title>
			<mat-card-content class="flex flex-col">
				<p>{{ "SETTINGS-USER_PASSWORD_CONDITIONS" | translate }}</p>
				<ul class="list-inside list-disc">
					<li>
						{{ "SETTINGS-USER_PASSWORD_CONDITIONS_CHARACTERS" | translate }}
					</li>
					<li>
						{{ "SETTINGS-USER_PASSWORD_CONDITIONS_LETTER" | translate }}
					</li>
					<li>
						{{ "SETTINGS-USER_PASSWORD_CONDITIONS_NUMBER" | translate }}
					</li>
					<li>
						{{ "SETTINGS-USER_PASSWORD_CONDITIONS_CAPSMALL_LETTERS" | translate }}
					</li>
					<li>
						{{ "SETTINGS-USER_PASSWORD_CONDITIONS_INDICATION" | translate }}
					</li>
				</ul>
				<div class="mt-4">
					<form
						class="flex flex-col"
						[formGroup]="pwdGroup">
						<mat-form-field
							appearance="outline"
							class="w-full">
							<mat-label>{{ "SETTINGS-USER_PASSWORD_CURRENT" | translate }}</mat-label>
							<input
								matInput
								required
								formControlName="currentPwd"
								[type]="hide ? 'password' : 'text'" />
							<mat-icon
								style="cursor: pointer"
								matSuffix
								(click)="hide = !hide"
								>{{ hide ? "visibility_off" : "visibility" }}</mat-icon
							>
						</mat-form-field>
						<mat-form-field
							appearance="outline"
							class="w-full">
							<mat-label>{{ "SETTINGS-USER_PASSWORD_NEW" | translate }}</mat-label>
							<input
								matInput
								required
								minlength="8"
								maxlength="64"
								formControlName="newPwd"
								[type]="hide ? 'password' : 'text'" />
							<mat-icon
								style="cursor: pointer"
								matSuffix
								(click)="hide = !hide"
								>{{ hide ? "visibility_off" : "visibility" }}</mat-icon
							>
						</mat-form-field>
						<mat-form-field
							appearance="outline"
							class="w-full">
							<mat-label>{{ "SETTINGS-USER_CONFIRMATION_PASSWORD" | translate }}</mat-label>
							<input
								matInput
								required
								minlength="8"
								maxlength="64"
								formControlName="confirmPwd"
								[type]="hide ? 'password' : 'text'" />
							<mat-icon
								style="cursor: pointer"
								matSuffix
								(click)="hide = !hide"
								>{{ hide ? "visibility_off" : "visibility" }}</mat-icon
							>
						</mat-form-field>
					</form>
				</div>
			</mat-card-content>
			<mat-card-actions>
				<div class="flex w-full flex-row justify-between">
					<div>
						<button
							mat-button
							*ngIf="!user.totp_enabled"
							color="primary"
							(click)="enable2FA()">
							<mat-icon>lock</mat-icon>
							{{ "users.user.settings.2fa.enable.label" | translate }}
						</button>
						<button
							mat-button
							*ngIf="user.totp_enabled"
							color="warn"
							(click)="disable2FA()">
							<mat-icon>lock_open</mat-icon>
							{{ "users.user.settings.2fa.disable.label" | translate }}
						</button>
					</div>
					<mat-spinner
						*ngIf="isLoading"
						color="primary"
						[diameter]="40"></mat-spinner>
					<button
						mat-raised-button
						color="primary"
						*ngIf="!isLoading"
						[disabled]="pwdGroup.invalid"
						(click)="changePwd()">
						{{ "core.edit" | translate }}
					</button>
				</div>
			</mat-card-actions>
		</mat-card>
	</div>
	<div class="flex flex-row gap-x-4">
		<mat-card
			appearance="outlined"
			class="flex-1">
			<mat-card-title>
				<div class="flex flex-row items-center justify-center">
					<mat-icon>payments</mat-icon>
				</div>
			</mat-card-title>
			<mat-card-content class="flex flex-col">
				<app-user-subscription></app-user-subscription>
			</mat-card-content>
		</mat-card>
		<mat-card
			appearance="outlined"
			class="flex-1">
			<mat-card-title>
				<div class="flex flex-row items-center justify-center">
					<mat-icon>settings</mat-icon>
				</div>
			</mat-card-title>
			<mat-card-content class="flex flex-col">
				<mat-form-field
					appearance="outline"
					class="w-full">
					<mat-label>Thème</mat-label>
					<mat-select
						[(value)]="selectedTheme"
						(valueChange)="updateTheme(selectedTheme)">
						<mat-option
							*ngFor="let item of themes"
							[value]="item.value">
							{{ item.label }}
						</mat-option>
					</mat-select>
					<mat-icon matPrefix>dark_mode&nbsp;</mat-icon>
				</mat-form-field>
				<mat-form-field
					appearance="outline"
					class="w-full">
					<mat-label>Langue</mat-label>
					<mat-select
						[(value)]="selectedLanguage"
						(valueChange)="updateLanguage(selectedLanguage)">
						<mat-option
							*ngFor="let item of langs"
							[value]="item.i18n">
							{{ item.language }}
						</mat-option>
					</mat-select>
					<mat-icon matPrefix>translate&nbsp;</mat-icon>
				</mat-form-field>
			</mat-card-content>
		</mat-card>
	</div>
</div>
