import { AfterViewInit, Component, OnInit } from "@angular/core";
import { Protocol } from "../../../interfaces/protocols/protocol";
import { ActivatedRoute, Router } from "@angular/router";
import { MitpClientService } from "../../../mitp-client.service";
import { MitpManagerService } from "../../../services/mitp-manager.service";
import { ProtocolContentDraft } from "../../../interfaces/protocols/protocol-content-draft";
import tinymce from "tinymce";
import { MatDialog } from "@angular/material/dialog";
import { ProtocolLabelComponent } from "../protocol-label/protocol-label.component";
import { ProtocolTagsComponent } from "../protocol-tags/protocol-tags.component";
import { ProtocolApproversNotifyComponent } from "../protocol-approvers-notify/protocol-approvers-notify.component";
import { ProtocolAuthorsComponent } from "../protocol-authors/protocol-authors.component";
import { ProtocolErrorsComponent } from "../protocol-errors/protocol-errors.component";
import { ProtocolInteractiveOptionsComponent } from "../tools/protocol-interactive-options/protocol-interactive-options.component";
import { ToastrService } from "ngx-toastr";
import { ProtocolScriptEditorComponent } from "../protocol-script-editor/protocol-script-editor.component";
import { ProtocolInitComponent } from "../protocol-init/protocol-init.component";
import { ProtocolValidateComponent } from "../protocol-validate/protocol-validate.component";
import { ProtocolCodificationComponent } from "../protocol-codification/protocol-codification.component";
import { ProtocolDownloadComponent } from "../protocol-download/protocol-download.component";
import { ProtocolShareByLinkComponent } from "../share/protocol-share-by-link/protocol-share-by-link.component";
import { ProtocolShareComponent } from "../share/protocol-share/protocol-share.component";
import { ToolbarServiceService } from "../../../services/toolbar-service.service";
import { UserService } from "../../../services/user.service";
import { SelectiveAlgorithmsComponent } from "../tools/selective-algorithms/selective-algorithms.component";
import { InteractiveComponent } from "../../../interfaces/interactive-component";
import { ProtocolEmbeddedComponent } from "../tools/protocol-embedded/protocol-embedded.component";
import { ProtocolImportComponent } from "../protocol-import/protocol-import.component";
import { ProtocolUpdatesComponent } from "../protocol-updates/protocol-updates.component";
import { JanusComponent } from "../janus/janus.component";
import { ProtocolAttachmentsComponent } from "../attachments/protocol-attachments/protocol-attachments.component";
import { ProtocolAttachment } from "../../../entities/protocol-attachment";
import * as fs from "file-saver";
import { ProtocolImportPdfComponent } from "../protocol-import-pdf/protocol-import-pdf.component";
import { UptodateComponent } from "../../knowledge/uptodate/uptodate.component";
import { UpToDateSearchResult } from "../../../entities/uptodate/UpToDateSearchResponse";
import { VidalRecosComponent } from "../../knowledge/vidal/vidal-recos/vidal-recos.component";
import { VidalRecosResult } from "../../../entities/vidal/vidal-recos-result";
import { ProtocolDiagramComponent } from "../protocol-diagram/protocol-diagram.component";
import { ProtocolDrugsComponent } from "../protocol-drugs/protocol-drugs.component";
import { FileService } from "../../../services/file.service";
import { ProtocolService } from "../../../services/protocol.service";
import { SearchVideosComponent } from "../tools/search-videos/search-videos.component";
import { SearchAttachmentsComponent } from "../tools/search-attachments/search-attachments.component";
import { UtilsService } from "../../../services/utils.service";
import { ProtocolViewerService } from "../../../services/protocol-viewer.service";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { EditorComponent } from "@tinymce/tinymce-angular";
import { MatPrefix } from "@angular/material/form-field";
import { MatDivider } from "@angular/material/divider";
import { MatMenu, MatMenuItem, MatMenuTrigger } from "@angular/material/menu";
import { NgFor, NgIf, NgOptimizedImage } from "@angular/common";
import { MatIcon } from "@angular/material/icon";
import { MatTooltip } from "@angular/material/tooltip";
import { OrderByPipe } from "ngx-pipes";
import { ReplaceAttachmentComponent } from "../attachments/replace-attachment/replace-attachment.component";
import { DrugMigratorService } from "../../../services/drug-migrator.service";
import { first } from "rxjs";
import { DrugEquivalent } from "../../../entities/pharmacy/drug-equivalent";
import { ProtocolDrugsEquivalentsComponent } from "../protocol-drugs-equivalents/protocol-drugs-equivalents.component";
import { NgxTolgeeModule, TranslateService } from "@tolgee/ngx";

@Component({
	selector: "app-edit-protocol",
	templateUrl: "./edit-protocol.component.html",
	styleUrls: ["./edit-protocol.component.scss"],
	standalone: true,
	imports: [
		MatTooltip,
		MatIcon,
		NgIf,
		MatMenuTrigger,
		MatMenu,
		MatMenuItem,
		NgFor,
		NgOptimizedImage,
		MatDivider,
		MatPrefix,
		EditorComponent,
		ReactiveFormsModule,
		FormsModule,
		NgxTolgeeModule,
	],
})
export class EditProtocolComponent implements OnInit, AfterViewInit {
	protocolId: number = null;
	protocol: Protocol = null;
	protocolContent: ProtocolContentDraft = { code: "", content: "" };
	isLoading = false;
	isLocked = true;
	protocols: Protocol[] = [];
	css = "";
	drugEquivalents: DrugEquivalent[] = [];

	constructor(
		private route: ActivatedRoute,
		public http: MitpClientService,
		public manager: MitpManagerService,
		private userService: UserService,
		private toolbarService: ToolbarServiceService,
		private dialog: MatDialog,
		private toastr: ToastrService,
		private translateService: TranslateService,
		private router: Router,
		public fileService: FileService,
		private protocolService: ProtocolService,
		private utils: UtilsService,
		private protocolViewer: ProtocolViewerService,
		private oPipe: OrderByPipe,
		private drugMigrator: DrugMigratorService
	) {}

	ngOnInit(): void {
		this.protocolId = this.route.snapshot.params["id"];
		this.protocol = history.state.data as Protocol;
		if (this.protocol == null) {
			this.getProtocolMeta();
		} else {
			this.updateToolbar();
		}
		this.loadContent();
		this.drugMigrator
			.getEquivalentsForProtocol(this.protocolId)
			.pipe(first())
			.subscribe((response) => {
				this.drugEquivalents = this.drugMigrator.drugsToMigrate(this.protocolContent, response);
			});
	}

	ngAfterViewInit(): void {
		this.lock();
	}

	lock() {
		if (this.isOnlyManager()) {
			tinymce.activeEditor.mode.set("readonly");
			this.isLocked = true;
		} else {
			tinymce.activeEditor.mode.set("design");
			this.isLocked = false;
		}
	}

	setLoading(value: boolean) {
		if (value != this.isLoading) {
			this.isLoading = value;
			this.manager.showLoading(value);
		}
	}

	getProtocolMeta() {
		this.http.getProtocolById(this.protocolId).subscribe((protocol) => {
			this.protocol = protocol;
			this.updateToolbar();
			this.lock();
		});
	}

	loadContent() {
		this.setLoading(true);
		this.http.getDraftProtocol(this.protocolId).subscribe((response) => {
			this.protocolContent = response;
			this.setLoading(false);
		});
	}

	onEditorSetup($editor) {
		["pre", "p", "div", "h1", "h2", "h3", "h4", "h5", "h6"].forEach(function (name) {
			$editor.ui.registry.addButton(`style-${name}`, {
				text: name.toUpperCase(),
				onAction: function () {
					$editor.execCommand("mceToggleFormat", false, name);
				},
			});
		});
		$editor.on("init", () => {
			if (!this.isLocked) {
				$editor.focus();
			}
		});
	}

	uploadImage(cb) {
		const input = document.createElement("input");
		input.setAttribute("type", "file");
		input.setAttribute("accept", "image/*");

		input.onchange = function () {
			const file = input.files[0];

			const reader = new FileReader();
			reader.onload = function () {
				const id = "blobid" + new Date().getTime();
				const blobCache = tinymce.activeEditor.editorUpload.blobCache;
				if (typeof reader.result === "string") {
					const base64 = reader.result.split(",")[1];
					const blobInfo = blobCache.create(id, file, base64);
					blobCache.add(blobInfo);
					cb(blobInfo.blobUri(), { title: file.name });
				}
			};
			reader.readAsDataURL(file);
		};
		input.click();
	}

	save() {
		this.setLoading(true);
		return this.http
			.updateProtocolContent(this.protocolId, this.protocolContent)
			.toPromise()
			.then((response) => {
				this.setLoading(false);
				if (response.status == 204) {
					this.toastr.success(this.translateService.instant("EDIT-PROTOCOL_MESSAGE_SUCCESS"));
				} else {
					this.toastr.error(this.translateService.instant("core.message.error.unknown"));
				}
				tinymce.activeEditor.focus();
			});
	}

	editLabel() {
		this.dialog
			.open(ProtocolLabelComponent, {
				data: this.protocol,
				minWidth: "600px",
			})
			.afterClosed()
			.subscribe((label) => {
				if (label) {
					this.protocol.label = label.label;
					this.updateToolbar();
				}
			});
	}

	editTags() {
		this.dialog.open(ProtocolTagsComponent, {
			data: this.protocol,
			minWidth: "600px",
		});
	}

	notifyApprovers(): void {
		this.dialog.open(ProtocolApproversNotifyComponent, {
			data: this.protocol,
			minWidth: "600px",
			disableClose: true,
			autoFocus: false,
		});
	}

	assignAuthors(): void {
		this.dialog
			.open(ProtocolAuthorsComponent, {
				data: this.protocol,
				minWidth: "600px",
			})
			.afterClosed()
			.subscribe(() => {
				this.lock();
			});
	}

	searchAuthors(): void {
		this.manager.openInfoDialog(this.translateService.instant("protocol.edit.authors.search.external"));
	}

	isError(): boolean {
		return this.protocol?.errors.filter((e) => e.status == 0).length > 0;
	}

	showErrors() {
		this.dialog.open(ProtocolErrorsComponent, {
			data: this.protocol,
			minWidth: "600px",
		});
	}

	openInteractiveOptions() {
		this.dialog
			.open(ProtocolInteractiveOptionsComponent, {
				data: this.protocol,
				minWidth: "600px",
				disableClose: true,
			})
			.afterClosed()
			.subscribe((response) => {
				if (response) {
					tinymce.activeEditor.execCommand("mceInsertContent", false, response.html);
					this.protocolContent.code = `${response.script}\n${this.protocolContent.code}`;
				}
			});
	}

	testProtocol() {
		this.protocolViewer.viewProtocol(this.protocol, this.protocolContent);
	}

	requestSecurityApproval() {
		this.protocolService.postSecurityApproval(this.protocolId).subscribe((response) => {
			if (response.status == 204) {
				this.toastr.success(this.translateService.instant("protocol.edit.security.pending"));
			} else {
				this.toastr.error(this.translateService.instant("core.message.error.unknown"));
			}
		});
	}

	validateJS() {
		this.http.validateSecurityFingerprint(this.protocolId).subscribe((response) => {
			if (response.status === 204) {
				this.toastr.success(this.translateService.instant("protocol.edit.security.fingerprint.success"));
			} else {
				this.toastr.error(this.translateService.instant("core.message.error.unknown"));
			}
		});
	}

	loadScriptEditor() {
		this.dialog
			.open(ProtocolScriptEditorComponent, {
				data: this.protocolContent.code,
				disableClose: true,
				minWidth: "600px",
				width: "1000px",
				minHeight: "600px",
			})
			.afterClosed()
			.subscribe((response) => {
				if (response) {
					this.protocolContent.code = response;
					this.save();
				}
			});
	}

	isInitiated(): boolean {
		return this.protocol?.updates.length > 0;
	}

	deleteProtocol() {
		if (!this.isInitiated()) {
			this.manager
				.showConfirmDialog(
					this.translateService.instant("protocol.delete.confirm"),
					this.translateService.instant("core.delete"),
					this.protocol.label
				)
				.afterClosed()
				.subscribe((answer) => {
					if (answer) {
						this.http.deleteProtocol(this.protocolId).subscribe((response) => {
							if (response.status == 204) {
								this.toastr.success(this.translateService.instant("protocol.delete.success"));
								this.router.navigate(["my-protocols"]).then();
							} else {
								this.toastr.error(this.translateService.instant("core.message.error.unknown"));
							}
						});
					} else {
						this.toastr.info(this.translateService.instant("protocol.delete.cancel"));
					}
				});
		}
	}

	initProtocol() {
		this.dialog
			.open(ProtocolInitComponent, {
				data: this.protocol,
				minWidth: "600px",
			})
			.afterClosed()
			.subscribe((response) => {
				if (response) {
					this.protocol.status = 1;
					this.updateToolbar();
					//TODO
					//this.protocol.updates.push({id: 0, updated: ""})
				}
			});
	}

	archiveProtocol() {
		this.http.archiveProtocol(this.protocolId).subscribe((response) => {
			if (response.status == 204) {
				this.toastr.success(this.translateService.instant("protocol.archive.success"));
				this.router.navigate(["my-protocols"]);
			} else {
				this.toastr.error(this.translateService.instant("core.message.error.unknown"));
			}
		});
	}

	disableProtocol() {
		this.http.disableProtocol(this.protocolId).subscribe((response) => {
			if (response.status == 204) {
				this.toastr.success(this.translateService.instant("protocol.disable.success"));
				this.protocol.status = 0;
				this.updateToolbar();
			} else {
				this.toastr.error(this.translateService.instant("core.message.error.unknown"));
			}
		});
	}

	publishProtocol() {
		this.dialog
			.open(ProtocolValidateComponent, {
				data: this.protocol,
				minWidth: "600px",
				autoFocus: false,
			})
			.afterClosed()
			.subscribe((response) => {
				if (response) {
					this.protocol.status = 1;
					this.updateToolbar();
				} else {
					this.toastr.info(this.translateService.instant("protocol.publish.cancel"));
				}
			});
	}

	setCodification() {
		this.dialog
			.open(ProtocolCodificationComponent, {
				data: this.protocol,
				minWidth: "600px",
			})
			.afterClosed()
			.subscribe((response) => {
				if (response) {
					this.protocol.codification = response;
				}
			});
	}

	showHistory() {
		this.dialog.open(ProtocolUpdatesComponent, {
			data: this.protocol,
			minWidth: "600px",
			autoFocus: false,
		});
	}

	downloadProtocol() {
		this.dialog.open(ProtocolDownloadComponent, {
			data: this.protocol,
			minWidth: "400px",
		});
	}

	shareByLink() {
		this.dialog.open(ProtocolShareByLinkComponent, {
			data: this.protocol,
			minWidth: "600px",
		});
	}

	shareProtocol() {
		this.dialog.open(ProtocolShareComponent, {
			data: this.protocol,
			minWidth: "600px",
		});
	}

	isAuthor(): boolean {
		return (
			this.protocol?.authors.map((e) => e.id).includes(this.userService.getUserId()) ||
			this.protocol?.approvers.map((e) => e.id).includes(this.userService.getUserId()) ||
			(this.protocol?.managers.map((e) => e.id).includes(this.userService.getUserId()) &&
				this.protocol?.updates.length > 0)
		);
	}

	isManager(): boolean {
		return this.protocol?.managers.map((e) => e.id).includes(this.userService.getUserId());
	}

	isOnlyManager(): boolean {
		return (
			this.isManager() &&
			!this.protocol?.authors.map((e) => e.id).includes(this.userService.getUserId()) &&
			!this.protocol?.approvers.map((e) => e.id).includes(this.userService.getUserId())
		);
	}

	isApprover(): boolean {
		return (
			this.protocol?.approvers.map((e) => e.id).includes(this.userService.getUserId()) ||
			(this.protocol?.managers.map((e) => e.id).includes(this.userService.getUserId()) &&
				this.protocol?.updates.length > 0)
		);
	}

	clearScript(): void {
		this.protocolContent.code = "";
		this.save();
	}

	formatTables() {
		const dom = new DOMParser().parseFromString(this.protocolContent.content, "text/html");
		const tables = dom.getElementsByTagName("table");
		for (let i = 0; i < tables.length; i++) {
			const table = tables[i];
			table.removeAttribute("width");
			table.setAttribute("style", "border-color: #000000; width: 100%; margin-left: auto; margin-right: auto;");
			table.setAttribute("border", "1");
			table.setAttribute("cellspacing", "0");
		}
		const tds = dom.getElementsByTagName("td");
		for (let i = 0; i < tds.length; i++) {
			const td = tds[i];
			td.removeAttribute("width");
		}
		const pTags = dom.querySelectorAll("td > p, th > p");
		pTags.forEach((tag) => {
			const div = document.createElement("div");
			div.innerHTML = tag.innerHTML;
			tag.parentNode.replaceChild(div, tag);
		});
		this.protocolContent.content = dom.body.innerHTML;
	}

	////////////////////  Tools  \\\\\\\\\\\\\\\\\\\\

	formatBlanks(): void {
		const dom = new DOMParser().parseFromString(this.protocolContent.content, "text/html");
		const bTags = dom.querySelectorAll("h1, h2, h3, h4, h5, h6, p, div");
		bTags.forEach((tag) => {
			if (tag.innerHTML == "&nbsp;" || tag.innerHTML == "") {
				tag.remove();
			}
		});
		const tTags = dom.querySelectorAll("table");
		tTags.forEach((tag) => {
			tag.insertAdjacentHTML("afterend", "<p>&nbsp;</p>");
		});
		this.protocolContent.content = dom.body.innerHTML;
	}

	newLine(): void {
		this.protocolContent.content = `${this.protocolContent.content}<p></p>`;
	}

	////////////////////  Diagram  \\\\\\\\\\\\\\\\\\\\

	loadDiagram(): void {
		const node = tinymce.activeEditor.selection.getEnd().closest("svg");
		if (node == null) {
			this.createDiagram();
		} else {
			this.editDiagram();
		}
	}

	createDiagram(): void {
		this.dialog
			.open(ProtocolDiagramComponent, {
				data: { markdown: null, type: "TD" },
				width: "80%",
				height: "64vh",
				disableClose: true,
				autoFocus: false,
			})
			.afterClosed()
			.subscribe((response) => {
				if (response) {
					const serializer = new XMLSerializer();
					tinymce.activeEditor.execCommand("mceInsertContent", false, serializer.serializeToString(response));
				}
			});
	}

	editDiagram(): void {
		const node = tinymce.activeEditor.selection.getEnd().closest("svg");
		if (node == null) {
			this.toastr.warning(this.translateService.instant("protocol.tools.diagram.chart.edit.warning.select"));
		} else {
			const markdown = node.dataset.markdown;
			const type = node.dataset.type;
			if (markdown && type) {
				this.dialog
					.open(ProtocolDiagramComponent, {
						data: { markdown: markdown, type: type },
						width: "80%",
						height: "64vh",
						disableClose: true,
						autoFocus: false,
					})
					.afterClosed()
					.subscribe((diagram) => {
						if (diagram) {
							node.replaceWith(diagram.documentElement);
						} else {
							this.toastr.info(this.translateService.instant("core.message.nomodification"));
						}
					});
			} else {
				this.toastr.error(this.translateService.instant("protocol.tools.diagram.chart.error.bad-format"));
			}
		}
	}

	deleteDiagram(): void {
		const node = tinymce.activeEditor.selection.getEnd().closest("svg");
		if (node == null) {
			this.toastr.warning(this.translateService.instant("protocol.tools.diagram.chart.delete.warning"));
		} else {
			node.remove();
			tinymce.activeEditor.focus();
		}
	}

	loadSelectiveAlgos(): void {
		const node = tinymce.activeEditor.selection.getNode().closest("mitp-algo");
		if (node == null) {
			this.createSelectiveAlgos();
		} else {
			this.editSelectiveAlgos();
		}
	}

	////////////////////  Selective Algos  \\\\\\\\\\\\\\\\\\\\

	createSelectiveAlgos(): void {
		this.dialog
			.open(SelectiveAlgorithmsComponent, {
				data: null,
				minWidth: "900px",
				autoFocus: false,
				disableClose: true,
			})
			.afterClosed()
			.subscribe((response) => {
				if (response) {
					this.insertSelectiveAlgo(response);
				}
			});
	}

	editSelectiveAlgos(): void {
		const node = tinymce.activeEditor.selection.getNode().closest("mitp-algo");
		if (node == null) {
			this.toastr.warning(this.translateService.instant("protocol.tools.diagram.chart.edit.warning.select"));
			return;
		}
		const algoJson = (node as HTMLElement).dataset.dataAlgo;
		if (!algoJson) {
			this.toastr.error(this.translateService.instant("protocol.tools.diagram.chart.error.bad-format"));
			return;
		}
		this.dialog
			.open(SelectiveAlgorithmsComponent, {
				data: JSON.parse(algoJson),
				minWidth: "900px",
				autoFocus: false,
				disableClose: true,
			})
			.afterClosed()
			.subscribe((response) => {
				if (response) {
					this.deleteSelectiveAlgos(node);
					this.insertSelectiveAlgo(response);
				}
			});
	}

	deleteSelectiveAlgos(node?: Element): void {
		if (node == null) {
			node = tinymce.activeEditor.selection.getNode().closest("mitp-algo");
		}
		if (node == null) {
			this.toastr.warning(this.translateService.instant("protocol.tools.diagram.chart.delete.warning"));
		} else {
			const id = node.getAttribute("id");
			const splittedCode = this.protocolContent.code.split(`/* ${id} */`);
			if (splittedCode.length == 3) {
				node.remove();
				this.protocolContent.code = (splittedCode[0] + splittedCode[2]).replace(/\s\s+/g, " ");
			} else {
				this.toastr.warning(this.translateService.instant("protocol.tools.diagram.chart.error.bad-format"));
			}
		}
	}

	addEmbeddedProtocol() {
		this.dialog
			.open(ProtocolEmbeddedComponent, {
				minWidth: "600px",
			})
			.afterClosed()
			.subscribe((response) => {
				if (response) {
					tinymce.activeEditor.execCommand("mceInsertContent", false, response);
				}
			});
	}

	unlock(): void {
		this.isLocked = false;
		tinymce.activeEditor.mode.set("design");
	}

	private updateToolbar() {
		this.toolbarService.protocolSubject.next(this.protocol);
	}

	private insertSelectiveAlgo(response: InteractiveComponent): void {
		tinymce.activeEditor.execCommand("mceInsertContent", false, response.html);
		this.protocolContent.code = `${response.script}\n${this.protocolContent.code}`;
	}

	importWord(): void {
		this.dialog
			.open(ProtocolImportComponent, {
				data: {
					showAlert: this.protocolContent.content.trim().length > 0,
				},
				minWidth: "600px",
				disableClose: true,
			})
			.afterClosed()
			.subscribe((content) => {
				if (content) {
					this.protocolContent.content = content.value;
				}
			});
	}

	loadDrugs(): void {
		const node = tinymce.activeEditor.selection.getEnd().closest("mitp-drug");
		let data = null;
		if (node != null) {
			data = {
				reference: node.getAttribute("reference"),
				text: node.innerHTML,
			};
		}
		this.dialog
			.open(ProtocolDrugsComponent, {
				data: data,
				width: "600px",
			})
			.afterClosed()
			.subscribe((response) => {
				if (response) {
					const mitpDrug = document.createElement("mitp-drug");
					mitpDrug.setAttribute("atc", response.atc);
					mitpDrug.setAttribute("reference", response.identifiers["ucd"]);
					mitpDrug.setAttribute("dci", response.dci);
					mitpDrug.innerText = response.text;
					if (node != null) {
						node.remove();
					}
					const serializer = new XMLSerializer();
					tinymce.activeEditor.execCommand("mceInsertContent", false, serializer.serializeToString(mitpDrug));
				}
			});
	}

	openChat(): void {
		this.manager.openInfoDialog(this.translateService.instant("core.coming-soon"));
	}

	openJanus(): void {
		this.dialog.open(JanusComponent, {
			width: "300px",
			height: "800px",
			disableClose: true,
			autoFocus: false,
			hasBackdrop: false,
		});
	}

	toChecklist(): void {
		if (!this.protocolContent.code.includes("input[type=checkbox]:checked+label")) {
			this.protocolContent.code +=
				"\n\nvar css = 'input[type=checkbox]:checked+label{opacity:0.33;text-decoration:line-through}';\n" +
				"var styleTag = document.createElement('style');\n" +
				"document.body.appendChild(styleTag);\n" +
				"styleTag.type = 'text/css';\n" +
				"styleTag.appendChild(document.createTextNode(css));";
		}
		const node = tinymce.activeEditor.selection.getNode();
		if (!["P", "LI"].includes(node.tagName)) {
			this.toastr.error(this.translateService.instant("protocol.edit.tools.todolist.errors.text-type"));
			return;
		}
		if (node.innerHTML.trim() === "" || node.innerHTML === "&nbsp;" || node.innerHTML.trim() == "<br>") {
			this.toastr.warning(this.translateService.instant("protocol.edit.tools.todolist.errors.empty-phrase"));
			return;
		}
		const id = Date.now().toString(36);
		node.innerHTML = `<input id="${id}" type="checkbox" value="${id}" /><label for="${id}">${node.innerHTML}</label>`;
		// node.remove();
		// let serializer = new XMLSerializer();
		// tinymce.activeEditor.execCommand('mceInsertContent', false, serializer.serializeToString(node));
	}

	openAttachments(): void {
		this.dialog
			.open(ProtocolAttachmentsComponent, {
				data: this.protocol,
				width: "600px",
				maxHeight: "80%",
				disableClose: true,
			})
			.afterClosed()
			.subscribe((response) => {
				this.protocol.attachments = this.oPipe.transform([...this.protocol.attachments, response], "label");
			});
	}

	downloadAttachment(attachment: ProtocolAttachment): void {
		this.manager.showLoading(true);
		this.protocolService.downloadProtocolAttachment(this.protocol.id, attachment.id).subscribe({
			next: (response) => {
				this.manager.showLoading(false);
				const file = new Blob([response.body], {
					type: this.fileService.getMimeType(attachment.extension) || "",
				});
				fs.saveAs(file, `${attachment.label}.${attachment.extension}`);
			},
			error: () => {
				this.manager.showLoading(false);
			},
		});
	}

	createGlassesProtocol(): void {
		this.router.navigate(["glasses", this.protocolId, "edit"]).then();
	}

	importPDF(): void {
		this.dialog
			.open(ProtocolImportPdfComponent, {
				width: "600px",
				disableClose: true,
			})
			.afterClosed()
			.subscribe((content) => {
				if (content) {
					this.protocolContent.content = content.content;
				}
			});
	}

	uptodate(): void {
		this.dialog
			.open(UptodateComponent, {
				width: "80%",
				height: "70%",
				disableClose: true,
				autoFocus: false,
			})
			.afterClosed()
			.subscribe((response: UpToDateSearchResult) => {
				if (response) {
					const link = `<a href="https://www.uptodate.com${response.url}" data-href="mitp://uptodate/${response.id}">UpToDate&#9415;: ${response.title}</a>`;
					tinymce.activeEditor.execCommand("mceInsertContent", false, link);
				}
			});
	}

	vidalRecos(): void {
		this.dialog
			.open(VidalRecosComponent, {
				width: "80%",
				height: "70%",
				disableClose: true,
				autoFocus: false,
			})
			.afterClosed()
			.subscribe((response: VidalRecosResult) => {
				if (response) {
					const link = `<a href="mitp://vidal/recos/${response.id}">VIDAL Recos&reg;: ${response.title}</a>`;
					tinymce.activeEditor.execCommand("mceInsertContent", false, link);
				}
			});
	}

	openSwissPedDose(): void {
		this.manager.openInfoDialog("Cette fonctionnalité sera bientôt disponible.");
	}

	toggleSharing(item: ProtocolAttachment): void {
		this.protocolService.toggleAttachmentSharingStatus(this.protocol.id, item.id, !item.enable_sharing).subscribe({
			next: (response) => {
				if (response.status === 204) {
					this.toastr.success(
						this.translateService.instant(
							`protocol.attachments.share.${item.enable_sharing ? "disable" : "enable"}.success`
						)
					);
					item.enable_sharing = !item.enable_sharing;
				} else {
					this.toastr.error(this.translateService.instant("core.message.error.unknown"));
				}
			},
			error: () => {
				this.toastr.error(this.translateService.instant("core.message.error.unknown"));
			},
		});
	}

	deleteAttachment(item: ProtocolAttachment): void {
		this.manager
			.showConfirmDialog(
				this.translateService.instant("protocol.attachments.remove.question", { label: item.label }),
				this.translateService.instant("core.delete"),
				""
			)
			.afterClosed()
			.subscribe({
				next: (result) => {
					if (result) {
						this.protocolService.deleteAttachment(this.protocol.id, item.id).subscribe({
							next: (response) => {
								if (response.status === 204) {
									this.toastr.success(
										this.translateService.instant("protocol.attachments.remove.success")
									);
									this.protocol.attachments = this.protocol.attachments.filter(
										(e) => e.id !== item.id
									);
								} else {
									this.toastr.error(this.translateService.instant("core.message.error.unknown"));
								}
							},
							error: () => {
								this.toastr.error(this.translateService.instant("core.message.error.unknown"));
							},
						});
					} else {
						this.toastr.info(this.translateService.instant("core.message.nomodification"));
					}
				},
			});
	}

	insertVideos(): void {
		this.dialog
			.open(SearchVideosComponent, {
				minWidth: "600px",
			})
			.afterClosed()
			.subscribe((response) => {
				if (response) {
					tinymce.activeEditor.execCommand("mceInsertContent", false, response);
				}
			});
	}

	convertToTel(): void {
		const selectedContent = tinymce.activeEditor.selection.getContent();
		if (selectedContent.trim() === "") {
			this.toastr.warning(
				this.translateService.instant("protocol.edit.tools.integration.convert-tel.error.empty")
			);
			return;
		}
		if (!this.utils.isTel(selectedContent.trim())) {
			this.toastr.warning(
				this.translateService.instant("protocol.edit.tools.integration.convert-tel.error.number")
			);
			return;
		}
		const tel = `<a href="tel:${selectedContent}">${selectedContent}</a>`;
		tinymce.activeEditor.selection.setContent(tel);
	}

	insertAppendix(): void {
		this.dialog
			.open(SearchAttachmentsComponent, {
				data: this.protocol.id,
				minWidth: "600px",
			})
			.afterClosed()
			.subscribe((response) => {
				if (response) {
					tinymce.activeEditor.execCommand("mceInsertContent", false, response);
				}
			});
	}

	replaceAttachment(attachment: ProtocolAttachment): void {
		this.dialog.open(ReplaceAttachmentComponent, {
			data: { protocol: this.protocol, attachment: attachment },
			minWidth: "600px",
			autoFocus: false,
			disableClose: true,
		});
	}

	loadDrugEquivalents(): void {
		this.dialog
			.open(ProtocolDrugsEquivalentsComponent, {
				data: { equivalents: this.drugEquivalents },
				width: "600px",
				disableClose: true,
				autoFocus: false,
			})
			.afterClosed()
			.subscribe((response) => {
				if (response) {
					this.protocolContent.content = this.drugMigrator.migrateContentDrugs(
						this.protocolContent,
						response
					);
					this.drugEquivalents = this.drugMigrator.drugsToMigrate(this.protocolContent, this.drugEquivalents);
				}
			});
	}
}
