import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { GuidelinesComponent } from "./guidelines/guidelines.component";
import { UptodateComponent } from "./uptodate/uptodate.component";
import { MatDialogModule } from "@angular/material/dialog";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { ReactiveFormsModule } from "@angular/forms";
import { MatInputModule } from "@angular/material/input";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatTooltipModule } from "@angular/material/tooltip";
import { VidalRecosComponent } from "./vidal/vidal-recos/vidal-recos.component";
import { VidalRecosService } from "./vidal/vidal-recos/vidal-recos.service";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatSortModule } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table";

@NgModule({
	imports: [
		CommonModule,
		MatDialogModule,
		MatButtonModule,
		MatIconModule,
		ReactiveFormsModule,
		MatInputModule,
		MatAutocompleteModule,
		MatProgressSpinnerModule,
		MatExpansionModule,
		MatTooltipModule,
		MatPaginatorModule,
		MatSortModule,
		MatTableModule,
		GuidelinesComponent,
		UptodateComponent,
		VidalRecosComponent,
	],
	providers: [VidalRecosService],
})
export class KnowledgeModule {}
