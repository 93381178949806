import { Component, OnInit } from "@angular/core";
import { MitpClientService } from "../../../mitp-client.service";
import { MitpManagerService } from "../../../services/mitp-manager.service";
import { ToastrService } from "ngx-toastr";
import { User } from "../../../interfaces/users/user";
import {
	AbstractControlOptions,
	UntypedFormBuilder,
	UntypedFormGroup,
	Validators,
	ReactiveFormsModule,
	FormsModule,
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { Theme } from "../../../enums/Theme";
import { Language } from "../../../interfaces/language";
import { ELanguage as Lang } from "../../../enums/ELanguage";
import { TwoFAComponent } from "../two-fa/two-fa.component";
import { UserService } from "../../../services/user.service";
import { AuthService } from "../../../auth.service";
import { MatOption } from "@angular/material/core";
import { MatSelect } from "@angular/material/select";
import { UserSubscriptionComponent } from "../user-subscription/user-subscription.component";
import { MatProgressSpinner } from "@angular/material/progress-spinner";
import { MatButton } from "@angular/material/button";
import { NgIf, NgFor } from "@angular/common";
import { MatInput } from "@angular/material/input";
import { MatFormField, MatLabel, MatSuffix, MatPrefix } from "@angular/material/form-field";
import { MatIcon } from "@angular/material/icon";
import { MatCard, MatCardTitle, MatCardContent, MatCardActions } from "@angular/material/card";
import { NgxTolgeeModule, TranslateService } from "@tolgee/ngx";

@Component({
	selector: "app-settings-user",
	templateUrl: "./settings-user.component.html",
	styleUrls: ["./settings-user.component.scss"],
	standalone: true,
	imports: [
		MatCard,
		MatCardTitle,
		MatIcon,
		MatCardContent,
		MatFormField,
		MatLabel,
		MatInput,
		ReactiveFormsModule,
		FormsModule,
		MatSuffix,
		MatCardActions,
		NgIf,
		MatButton,
		MatProgressSpinner,
		UserSubscriptionComponent,
		MatSelect,
		NgFor,
		MatOption,
		MatPrefix,
		NgxTolgeeModule,
	],
})
export class SettingsUserComponent implements OnInit {
	user: User = {
		departments: [],
		email: "",
		firstname: "",
		id: 0,
		institute: { id: 0, label: "" },
		job: { id: 0, label: "" },
		last_active: "",
		lastname: "",
		roles: [],
		rpps: "",
		smartphone: "",
		status: 0,
		totp_enabled: false,
	};
	pwdGroup: UntypedFormGroup;
	hide = true;
	isLoading = false;
	selectedTheme: Theme = Theme.SYSTEM;
	Theme = Theme;
	themes = [
		{ label: "Light", value: Theme.LIGHT },
		{ label: "Dark", value: Theme.DARK },
		{ label: "System", value: Theme.SYSTEM },
	];
	selectedLanguage: Lang;
	langs: Language[] = [
		{
			id: 1,
			language: "Français",
			iso: "fr",
			i18n: Lang.FRENCH,
		},
		{
			id: 2,
			language: "English",
			iso: "us",
			i18n: Lang.ENGLISH,
		},
	];

	constructor(
		private http: MitpClientService,
		private _formBuilder: UntypedFormBuilder,
		private dialog: MatDialog,
		private manager: MitpManagerService,
		private toastr: ToastrService,
		private translate: TranslateService,
		private userService: UserService,
		private authService: AuthService
	) {}

	ngOnInit(): void {
		this.pwdGroup = this._formBuilder.group(
			{
				currentPwd: ["", [Validators.required]],
				newPwd: ["", [Validators.required, Validators.minLength(8), Validators.maxLength(64)]],
				confirmPwd: ["", [Validators.required, Validators.minLength(8), Validators.maxLength(64)]],
			},
			{ validator: this.validatePwdAndPwd2 } as AbstractControlOptions
		);

		this.manager.showLoading(true);
		this.http.getUserProfil().subscribe((response) => {
			console.log(response);
			this.user = response["profil"];
			this.manager.showLoading(false);
		});

		this.userService.getUserConfig().subscribe((response) => {
			this.selectedTheme = response.theme;
			this.selectedLanguage = response.language;
		});

		// if(this.route.snapshot.queryParams['paymentResult'] != undefined){
		//     let result = this.route.snapshot.queryParams['paymentResult'];
		//     switch(result){
		//         case 'failed':
		//             this.dialog.open(SubscriptionFailedComponent).afterClosed().subscribe(() => {
		//
		//             });
		//             break;
		//         case 'successed':
		//             break;
		//     }
		// }
	}

	validatePwdAndPwd2(group: UntypedFormGroup) {
		const pwd = group.get("newPwd").value;
		const pwd2 = group.get("confirmPwd").value;
		return pwd == pwd2 ? null : { notSame: true };
	}

	changePwd() {
		this.isLoading = true;
		this.http.setUserPassword({ ...this.pwdGroup.value }).subscribe(
			(response) => {
				this.isLoading = false;
				if (response.status == 204) {
					this.toastr.success("Mot de passe changé avec succès.");
					this.pwdGroup.reset();
				} else {
					this.toastr.error("Une error inconnue est survenue !");
				}
			},
			() => {
				this.isLoading = false;
			}
		);
	}

	updateTheme(theme: Theme): void {
		this.http.updateTheme(theme).subscribe((response) => {
			if (response.status === 204) {
				this.toastr.success(this.translate.instant("users.user.settings.update.success"));
			} else {
				this.toastr.error(this.translate.instant("users.user.settings.update.error"));
			}
		});
	}

	updateLanguage(lang: Lang): void {
		this.http.updateLang(lang).subscribe((response) => {
			if (response.status === 204) {
				this.toastr.success(this.translate.instant("users.user.settings.update.success"));
			} else {
				this.toastr.error(this.translate.instant("users.user.settings.update.error"));
			}
		});
	}

	enable2FA(): void {
		this.dialog
			.open(TwoFAComponent, {
				width: "600px",
				data: { user: this.user },
				autoFocus: false,
				disableClose: true,
			})
			.afterClosed()
			.subscribe((response) => {
				if (response) {
					this.user.totp_enabled = true;
				} else {
					this.toastr.info(this.translate.instant("core.message.nomodification"));
				}
			});
	}

	disable2FA(): void {
		if (!this.authService.isSecureLogin()) {
			this.manager.openErrorDialog(this.translate.instant("users.user.settings.2fa.secure.required"));
			return;
		}
		this.manager
			.showConfirmDialog(
				this.translate.instant("users.user.settings.2fa.disable.confirm"),
				this.translate.instant("core.disable")
			)
			.afterClosed()
			.subscribe((response) => {
				if (response) {
					this.userService.disableMFA().subscribe((response) => {
						if (response.status === 204) {
							this.toastr.success(this.translate.instant("users.user.settings.2fa.disable.success"));
							this.user.totp_enabled = false;
						} else {
							this.toastr.error(this.translate.instant("core.message.error.unknown"));
						}
					});
				} else {
					this.toastr.info(this.translate.instant("core.message.nomodification"));
				}
			});
	}
}
