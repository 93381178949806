import { Component, Inject } from "@angular/core";
import {
	MAT_DIALOG_DATA,
	MatDialogRef,
	MatDialogContent,
	MatDialogActions,
	MatDialogClose,
} from "@angular/material/dialog";
import { MitpClientService } from "../../../mitp-client.service";
import { Protocol } from "../../../interfaces/protocols/protocol";
import { ToastrService } from "ngx-toastr";
import { UserService } from "../../../services/user.service";
import { MatProgressSpinner } from "@angular/material/progress-spinner";
import { NgIf } from "@angular/common";
import { MatButton } from "@angular/material/button";
import { MatIcon } from "@angular/material/icon";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { MatInput } from "@angular/material/input";
import { MatFormField, MatLabel, MatHint, MatPrefix } from "@angular/material/form-field";
import { NgxTolgeeModule, TranslateService } from "@tolgee/ngx";

@Component({
	selector: "app-protocol-label",
	templateUrl: "./protocol-label.component.html",
	styleUrls: ["./protocol-label.component.scss"],
	standalone: true,
	imports: [
		MatDialogContent,
		MatFormField,
		MatLabel,
		MatInput,
		ReactiveFormsModule,
		FormsModule,
		MatHint,
		MatIcon,
		MatPrefix,
		MatDialogActions,
		MatButton,
		MatDialogClose,
		NgIf,
		MatProgressSpinner,
		NgxTolgeeModule,
	],
})
export class ProtocolLabelComponent {
	isLoading: boolean = false;

	constructor(
		@Inject(MAT_DIALOG_DATA) public protocol: Protocol,
		private http: MitpClientService,
		private userService: UserService,
		private toaster: ToastrService,
		private dialog: MatDialogRef<ProtocolLabelComponent>,
		private translate: TranslateService
	) {}

	isProtocolManager(): boolean {
		return this.protocol?.managers.map((e) => e.id).includes(this.userService.getUserId());
	}

	apply(): void {
		this.isLoading = true;
		this.http.updateProtocolLabel(this.protocol.id, this.protocol.label).subscribe((label) => {
			this.isLoading = false;
			this.toaster.success(this.translate.instant("protocol.title.update.success"));
			this.dialog.close(label);
		});
	}
}
