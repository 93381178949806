import { Component } from "@angular/core";
import { MatDialogActions, MatDialogClose, MatDialogContent } from "@angular/material/dialog";
import { MatButtonModule } from "@angular/material/button";
import { NgxTolgeeModule } from "@tolgee/ngx";

@Component({
	selector: "app-legal-update-modal",
	standalone: true,
	imports: [MatDialogContent, MatDialogActions, MatButtonModule, MatDialogClose, NgxTolgeeModule],
	templateUrl: "./legal-update-modal.component.html",
	styleUrl: "./legal-update-modal.component.scss",
})
export class LegalUpdateModalComponent {}
