import { Component, Inject } from "@angular/core";
import {
	MAT_DIALOG_DATA,
	MatDialogClose,
	MatDialogContent,
	MatDialogRef,
	MatDialogTitle,
} from "@angular/material/dialog";
import { Protocol } from "../../../interfaces/protocols/protocol";
import { MitpClientService } from "../../../mitp-client.service";
import { ToastrService } from "ngx-toastr";
import { UserService } from "../../../services/user.service";
import { MatProgressSpinner } from "@angular/material/progress-spinner";
import { NgIf, NgOptimizedImage } from "@angular/common";
import { MatIcon } from "@angular/material/icon";
import { MatInput } from "@angular/material/input";
import { MatFormField, MatLabel, MatSuffix } from "@angular/material/form-field";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { MatRadioGroup, MatRadioButton } from "@angular/material/radio";
import { AuthorsFilterComponent } from "../../users/authors-filter/authors-filter.component";
import { MatButton, MatIconButton } from "@angular/material/button";
import { TagFilterComponent } from "../../tags/tag-filter/tag-filter.component";
import { MatStepper, MatStep, MatStepLabel, MatStepperPrevious, MatStepperNext } from "@angular/material/stepper";
import { MatProgressBar } from "@angular/material/progress-bar";
import { ProtocolService } from "../../../services/protocol.service";
import { finalize, first } from "rxjs";
import { ProtocolSafety } from "../../../entities/protocols/protocol-safety";
import { NgxTolgeeModule, TranslateService } from "@tolgee/ngx";

@Component({
	selector: "app-protocol-validate",
	templateUrl: "./protocol-validate.component.html",
	styleUrls: ["./protocol-validate.component.scss"],
	standalone: true,
	imports: [
		MatStepper,
		MatStep,
		MatStepLabel,
		TagFilterComponent,
		MatButton,
		MatStepperPrevious,
		MatStepperNext,
		AuthorsFilterComponent,
		MatRadioGroup,
		ReactiveFormsModule,
		FormsModule,
		MatRadioButton,
		MatFormField,
		MatLabel,
		MatInput,
		MatIcon,
		MatSuffix,
		NgIf,
		MatProgressSpinner,
		MatDialogTitle,
		MatIconButton,
		MatDialogClose,
		MatDialogContent,
		MatProgressBar,
		NgOptimizedImage,
		NgxTolgeeModule,
	],
})
export class ProtocolValidateComponent {
	isLoading = false;
	isVerifying = true;
	publishType = { update: "", notes: "" };
	safety: ProtocolSafety = { safe: false };
	requestLoading = false;

	constructor(
		@Inject(MAT_DIALOG_DATA) public protocol: Protocol,
		private http: MitpClientService,
		private userService: UserService,
		private toastr: ToastrService,
		private dialogRef: MatDialogRef<ProtocolValidateComponent>,
		private translate: TranslateService,
		private protocolService: ProtocolService
	) {
		protocolService
			.isProtocolSafe(protocol.id)
			.pipe(
				first(),
				finalize(() => (this.isVerifying = false))
			)
			.subscribe((response) => {
				this.safety = response;
			});
	}

	isOfflineAvailable(): boolean {
		return this.protocol.tags.filter((e) => e.section).length > 0;
	}

	validateProtocol() {
		this.isLoading = true;
		this.http.publishProtocol(this.protocol.id, this.publishType.update, this.publishType.notes).subscribe({
			next: (response) => {
				this.isLoading = false;
				if (response.status == 204) {
					this.toastr.success(this.translate.instant("protocol.publish.success"));
					this.dialogRef.close(true);
				} else {
					this.toastr.error(this.translate.instant("protocol.publish.error"));
				}
			},
			error: () => {
				this.isLoading = false;
			},
		});
	}

	isManager(): boolean {
		return (
			this.protocol.managers.map((e) => e.id).includes(this.userService.getUserId()) &&
			!this.protocol.approvers.map((e) => e.id).includes(this.userService.getUserId())
		);
	}

	requestReview(): void {
		this.requestLoading = true;
		this.protocolService
			.postSecurityApproval(this.protocol.id)
			.pipe(
				first(),
				finalize(() => (this.requestLoading = false))
			)
			.subscribe((result) => {
				if (result.status === 204) {
					this.toastr.success(this.translate.instant("protocol.edit.security.pending"));
				} else {
					this.toastr.error(this.translate.instant("core.message.error.unknown"));
				}
			});
	}
}
