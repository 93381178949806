<div
	class="flex flex-col"
	cdkDrag
	cdkDragRootElement=".cdk-overlay-pane">
	<div class="flex flex-row justify-between">
		<button
			mat-icon-button
			cdkDragHandle
			[matTooltip]="'janus.dialog.drag-handler.tooltip' | translate">
			<mat-icon>open_with</mat-icon>
		</button>
		<button
			mat-icon-button
			(click)="updateModalWidth()"
			[matTooltip]="'janus.dialog.size.tooltip' | translate">
			<mat-icon>pages</mat-icon>
		</button>
		<button
			mat-icon-button
			mat-dialog-close
			[matTooltip]="'janus.dialog.close.tooltip' | translate">
			<mat-icon>close</mat-icon>
		</button>
	</div>
	<div class="mx-4">
		<div>
			<mat-checkbox [(ngModel)]="advancedMode">{{ "janus.mode.advanced.label" | translate }}</mat-checkbox>
			<div
				class="my-2 text-xs italic text-red-900"
				*ngIf="advancedMode">
				{{ "janus.mode.advanced.warning" | translate }}
			</div>
			<mat-form-field
				appearance="outline"
				class="w-full">
				<mat-label>{{
					(advancedMode ? "janus.mode.advanced.search.placeholder" : "janus.mode.simple.search.placeholder")
						| translate
				}}</mat-label>
				<input
					matInput
					[(ngModel)]="searchKeyword"
					spellcheck="false"
					(keydown.enter)="search()" />
				<mat-icon
					matIconSuffix
					color="primary"
					(click)="search()"
					class="cursor-pointer"
					*ngIf="!isLoading"
					>search</mat-icon
				>
				<mat-spinner
					matIconSuffix
					class="mr-2"
					color="primary"
					diameter="24"
					*ngIf="isLoading"></mat-spinner>
			</mat-form-field>
		</div>
		<cdk-virtual-scroll-viewport
			itemSize="10"
			class="h-[60vh] w-full"
			*ngIf="!advancedMode">
			<div
				*cdkVirtualFor="let item of publications; last as isLast"
				class="my-2">
				<div
					class="flex flex-col"
					[matMenuTriggerFor]="menu">
					<div class="text-xs">{{ item.journal }}</div>
					<div class="text-sm font-bold">{{ item.title }}</div>
					<div>
						<span class="text-sm italic">{{ item.authors }}</span
						>, {{ item.year }}
					</div>
				</div>
				<mat-menu #menu="matMenu">
					<button
						mat-menu-item
						(click)="openUrl(item)"
						*ngIf="item.doi !== ''">
						<mat-icon>preview</mat-icon>
						<span>{{ "janus.mode.simple.actions.open.label" | translate }}</span>
					</button>
					<button
						mat-menu-item
						(click)="showAbstract(item)">
						<mat-icon>description</mat-icon>
						<span>{{ "janus.mode.simple.actions.abstract.label" | translate }}</span>
					</button>
					<button
						mat-menu-item
						(click)="createResume(item)">
						<mat-icon>edit_note</mat-icon>
						<span>{{ "janus.mode.simple.actions.resume.label" | translate }}</span>
					</button>
				</mat-menu>
				<mat-divider *ngIf="!isLast"></mat-divider>
			</div>
		</cdk-virtual-scroll-viewport>
		<div
			*ngIf="advancedMode"
			class="h-[35vh] w-full select-text">
			{{ aiAnswer }}
		</div>
	</div>
</div>
