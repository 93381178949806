<div mat-dialog-content>
	{{ "legal.update.notice" | translate }}
</div>
<div
	mat-dialog-actions
	[align]="'end'">
	<button
		mat-button
		mat-dialog-close>
		{{ "core.ok" | translate }}
	</button>
</div>
