import { Component, OnInit } from "@angular/core";
import { MatOptionSelectionChange, MatOption } from "@angular/material/core";
import { Protocol } from "../../../../interfaces/protocols/protocol";
import { UntypedFormControl, ReactiveFormsModule, FormsModule } from "@angular/forms";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { MitpClientService } from "../../../../mitp-client.service";
import { OrderByPipe } from "ngx-pipes";
import { MatDialogRef, MatDialogContent, MatDialogActions, MatDialogClose } from "@angular/material/dialog";
import * as fuzz from "fuzzball";
import { MatButton } from "@angular/material/button";
import { NgxColorsModule } from "ngx-colors";
import { MatRadioGroup, MatRadioButton } from "@angular/material/radio";
import { MatIcon } from "@angular/material/icon";
import { MatAutocompleteTrigger, MatAutocomplete } from "@angular/material/autocomplete";
import { MatInput } from "@angular/material/input";
import { MatFormField, MatLabel, MatSuffix, MatHint } from "@angular/material/form-field";
import { NgIf, NgFor, AsyncPipe } from "@angular/common";
import { NgxTolgeeModule } from "@tolgee/ngx";

@Component({
	selector: "app-protocol-embedded",
	templateUrl: "./protocol-embedded.component.html",
	styleUrls: ["./protocol-embedded.component.scss"],
	standalone: true,
	imports: [
		MatDialogContent,
		NgIf,
		MatFormField,
		MatLabel,
		MatInput,
		ReactiveFormsModule,
		MatAutocompleteTrigger,
		MatAutocomplete,
		MatOption,
		MatIcon,
		NgFor,
		MatSuffix,
		MatRadioGroup,
		FormsModule,
		MatRadioButton,
		MatHint,
		NgxColorsModule,
		MatDialogActions,
		MatButton,
		MatDialogClose,
		AsyncPipe,
		NgxTolgeeModule,
	],
})
export class ProtocolEmbeddedComponent implements OnInit {
	label = null;
	selectedProtocol = null;
	protocols: Protocol[];
	filteredProtocols: Observable<Protocol[]>;
	m1Control = new UntypedFormControl();
	private minLength: number = 3;
	color: string = "#42A5F5";
	caption: string = "";
	type: string = "link";

	LINK = "link";
	EMBEDDED = "embedded";

	constructor(
		private dialogRef: MatDialogRef<ProtocolEmbeddedComponent>,
		private http: MitpClientService,
		private oPipe: OrderByPipe
	) {}

	ngOnInit(): void {
		this.http.getProtocols().subscribe((users) => {
			this.protocols = users;
			this.filteredProtocols = this.m1Control.valueChanges.pipe(
				startWith(""),
				map((value: string | null) => (value.length > this.minLength ? this._filter(value) : []))
			);
		});
	}

	add(): void {
		let output;
		if (this.type === this.LINK) {
			output = this.getLinkOutput();
		} else {
			output = this.getEmbeddedOutput();
		}
		this.dialogRef.close(output);
	}

	private getLinkOutput(): string {
		return `<a href="mitp://protocols/${this.selectedProtocol.id}">${this.selectedProtocol.label}</a>`;
	}

	private getEmbeddedOutput(): string {
		return `<mitp-embedded type="protocol" legend="${this.caption}" color="${this.color}" data-id="${this.selectedProtocol.id}"><button class="embedded-protocol-collapsible" style="background-color:${this.color}">${this.caption}</button></mitp-embedded>`;
	}

	selectProtocol($event: MatOptionSelectionChange): void {
		this.selectedProtocol = $event.source.value;
	}

	displayFn(protocol: Protocol): string {
		return protocol && `${protocol.label}` ? `${protocol.label}` : "";
	}

	private _filter(value: any): Protocol[] {
		if (typeof value === "string") {
			const resultUsers = this.protocols.filter((it) => {
				return fuzz.partial_ratio(it.label.toLowerCase(), value.trim().toLowerCase()) > 75;
			});
			return this.oPipe.transform(resultUsers, ["label"]);
		}
	}

	isEnabled(): boolean {
		if (this.type === this.LINK) {
			return this.selectedProtocol;
		} else {
			return this.selectedProtocol && this.caption.length > 0;
		}
	}
}
