import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogContent, MatDialogActions, MatDialogClose } from "@angular/material/dialog";
import { Protocol } from "../../../../interfaces/protocols/protocol";
import { MitpClientService } from "../../../../mitp-client.service";
import {
	MatTableDataSource,
	MatTable,
	MatColumnDef,
	MatHeaderCellDef,
	MatHeaderCell,
	MatCellDef,
	MatCell,
	MatHeaderRowDef,
	MatHeaderRow,
	MatRowDef,
	MatRow,
} from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { ProtocolAccessToken } from "../../../../interfaces/protocols/protocol-access-token";
import { OrderByPipe } from "ngx-pipes";
import { MatProgressSpinner } from "@angular/material/progress-spinner";
import { MatButton } from "@angular/material/button";
import { CdkCopyToClipboard } from "@angular/cdk/clipboard";
import { MatIcon } from "@angular/material/icon";
import { NgIf, DatePipe } from "@angular/common";
import { NgxTolgeeModule, TranslateService } from "@tolgee/ngx";

@Component({
	selector: "app-protocol-share-by-link",
	templateUrl: "./protocol-share-by-link.component.html",
	styleUrls: ["./protocol-share-by-link.component.scss"],
	standalone: true,
	imports: [
		MatDialogContent,
		MatTable,
		MatSort,
		MatColumnDef,
		MatHeaderCellDef,
		MatHeaderCell,
		MatCellDef,
		MatCell,
		NgIf,
		MatIcon,
		CdkCopyToClipboard,
		MatHeaderRowDef,
		MatHeaderRow,
		MatRowDef,
		MatRow,
		MatPaginator,
		MatDialogActions,
		MatButton,
		MatDialogClose,
		MatProgressSpinner,
		DatePipe,
		NgxTolgeeModule,
	],
})
export class ProtocolShareByLinkComponent implements OnInit {
	displayedColumns: string[] = ["created", "byUser", "actions"];
	dataSource = new MatTableDataSource<ProtocolAccessToken>();
	tokens_all: ProtocolAccessToken[] = [];
	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild(MatSort) sort: MatSort;
	isLoading: boolean = false;

	constructor(
		@Inject(MAT_DIALOG_DATA) public protocol: Protocol,
		private window: Window,
		private http: MitpClientService,
		private oPipe: OrderByPipe,
		private translate: TranslateService
	) {}

	ngOnInit(): void {
		this.http.getProtocolAccessTokens(this.protocol.id).subscribe((tokens) => {
			this.tokens_all = tokens;
			this.dataSource.data = this.oPipe.transform(this.tokens_all, "-created");

			this.dataSource.paginator = this.paginator;
			this.dataSource.sort = this.sort;
		});
	}

	generateToken() {
		this.isLoading = true;
		this.http.createProtocolAccessToken(this.protocol.id).subscribe({
			next: (response) => {
				this.isLoading = false;
				this.tokens_all.push(response.body);
				this.dataSource.data = this.oPipe.transform(this.tokens_all, "-created");
			},
			error: () => {
				this.isLoading = false;
			},
		});
	}

	isExpired(token: ProtocolAccessToken): boolean {
		const dateLimit = new Date(token.created);
		dateLimit.setSeconds(dateLimit.getSeconds() + token.duration);
		const currentDate = new Date();
		return currentDate > dateLimit;
	}

	createEmail(token: ProtocolAccessToken) {
		const link = this.getPublicLink(token);
		const subject = `Protocole ${token.protocol.label}`;
		const body = this.translate.instant("protocol.share.token.message.content", {
			firstname: token.user.firstname,
			lastname: token.user.lastname,
			protocol: token.protocol.label,
			url: link,
		});
		this.window.location.href = `mailto:?subject=${subject}&body=${body}`;
	}

	getPublicLink(token: ProtocolAccessToken): string {
		return `https://api.medinthepocket.com/public/protocols?token=${token.token}`;
	}
}
