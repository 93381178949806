import { Component } from "@angular/core";
import { MatDialog, MatDialogActions, MatDialogClose, MatDialogContent } from "@angular/material/dialog";
import { MatButton } from "@angular/material/button";
import { NavigationSpecialtyService } from "../../../../services/navigation-specialty.service";
import { ToastrService } from "ngx-toastr";
import { NavigationSpecialtyItem } from "../../../../interfaces/navigation-specialty-item";
import { finalize, first } from "rxjs";
import { MatCard } from "@angular/material/card";
import { NavigationSpecialtyItemCreateComponent } from "../navigation-specialty-item-create/navigation-specialty-item-create.component";
import { MatIcon } from "@angular/material/icon";
import { MatSuffix } from "@angular/material/form-field";
import { MatMenu, MatMenuItem, MatMenuTrigger } from "@angular/material/menu";
import { MitpManagerService } from "../../../../services/mitp-manager.service";
import { UserService } from "../../../../services/user.service";
import { ENavigationSpecialtyItemCreateMode } from "../../../../enums/enavigation-specialty-item-create-mode";
import { OrderByPipe } from "ngx-pipes";
import { NgxTolgeeModule, TranslateService } from "@tolgee/ngx";

@Component({
	selector: "app-navigation-specialties-modal",
	standalone: true,
	imports: [
		MatDialogActions,
		MatButton,
		MatDialogClose,
		MatDialogContent,
		MatCard,
		MatIcon,
		MatSuffix,
		MatMenu,
		MatMenuItem,
		MatMenuTrigger,
		NgxTolgeeModule,
	],
	templateUrl: "./navigation-specialties-modal.component.html",
	styleUrl: "./navigation-specialties-modal.component.scss",
})
export class NavigationSpecialtiesModalComponent {
	specialties: NavigationSpecialtyItem[] = [];
	isLoading = true;

	constructor(
		private navigationSpecialtyService: NavigationSpecialtyService,
		private toastr: ToastrService,
		private dialog: MatDialog,
		private manager: MitpManagerService,
		private translate: TranslateService,
		private userService: UserService,
		private oPipe: OrderByPipe
	) {
		navigationSpecialtyService
			.getMyNavigationItems()
			.pipe(
				first(),
				finalize(() => (this.isLoading = false))
			)
			.subscribe((specialties) => {
				this.specialties = specialties;
			});
	}

	newSpecialty(): void {
		if (this.userService.getDepartmentsId().length === 0) {
			this.toastr.error(this.translate.instant("institute.navigation-specialties.create.error.no-department"));
			return;
		}
		this.dialog
			.open(NavigationSpecialtyItemCreateComponent, {
				data: {
					instituteId: this.userService.getInstituteId(),
					mode: ENavigationSpecialtyItemCreateMode.DEPARTMENT,
				},
				width: "600px",
				disableClose: true,
			})
			.afterClosed()
			.subscribe((response) => {
				if (response) {
					this.specialties.push(response);
					this.specialties = this.oPipe.transform(this.specialties, "label");
				}
			});
	}

	getDepartments(item: NavigationSpecialtyItem): string {
		if (item.departments.length > 0) {
			return item.departments.map((department) => department.label).join(", ");
		}
		return this.translate.instant("core.all");
	}

	getTags(item: NavigationSpecialtyItem): string {
		return item.tags.map((tag) => tag.label).join(", ");
	}

	deleteSpecialty(item: NavigationSpecialtyItem): void {
		this.manager
			.showConfirmDialog(
				this.translate.instant("institute.navigation-specialties.delete.confirm"),
				this.translate.instant("core.delete")
			)
			.afterClosed()
			.subscribe((response) => {
				if (response) {
					this.navigationSpecialtyService
						.deleteProtocolNavigationSpecialtyItem(item)
						.pipe(first())
						.subscribe((response) => {
							if (response.status === 204) {
								this.toastr.success(
									this.translate.instant("institute.navigation-specialties.delete.success")
								);
								this.specialties = this.specialties.filter((specialty) => specialty.id !== item.id);
							} else {
								this.toastr.error(this.translate.instant("core.message.error.unknown"));
							}
						});
				} else {
					this.toastr.info(this.translate.instant("core.message.nomodification"));
				}
			});
	}
}
