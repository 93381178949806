import { Injectable } from "@angular/core";
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { MitpManagerService } from "./mitp-manager.service";
import { AuthService } from "../auth.service";

@Injectable({
	providedIn: "root",
})
export class HttpInterceptorService implements HttpInterceptor {
	constructor(
		public mitpManager: MitpManagerService,
		private authService: AuthService
	) {}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if (this.authService.logoutIfSessionExpired()) {
			this.mitpManager.showLoading(false);
			this.mitpManager.openErrorDialog("Votre session a expiré ! Merci de vous reconnecter.");
			return;
		}
		return next.handle(req).pipe(catchError((error) => this.handleError(error)));
	}

	handleError(error: HttpErrorResponse) {
		this.mitpManager.showLoading(false);
		if (error.status == 401) {
			this.authService.logoutIfSessionExpired();
		}
		if (error.error.message != undefined) {
			switch (error.error.message) {
				case "Expired JWT Token":
					this.mitpManager.openErrorDialog("Votre session a expiré ! Merci de vous reconnecter.");
					break;
				case "Invalid credentials.":
					this.mitpManager.openErrorDialog("Indentifiants incorrects !");
					break;
				default:
					this.mitpManager.openErrorDialog(error.error.message);
			}
		}
		this.mitpManager.showLoading(false);
		return throwError(() => error);
	}
}
