import { Component, OnInit } from "@angular/core";
import { MitpClientService } from "../../../mitp-client.service";
import { MitpManagerService } from "../../../services/mitp-manager.service";
import { MatDialog } from "@angular/material/dialog";
import { ToastrService } from "ngx-toastr";
import { SubscriptionProcessComponent } from "../subscription-process/subscription-process.component";
import { ActivatedRoute, Router } from "@angular/router";
import { SubscriptionSuccessedComponent } from "../subscription-successed/subscription-successed.component";
import { SubscriptionFailedComponent } from "../subscription-failed/subscription-failed.component";
import { Subscription } from "../../../interfaces/subscription";
import { MatButton } from "@angular/material/button";
import { NgIf, DatePipe } from "@angular/common";

@Component({
	selector: "app-user-subscription",
	templateUrl: "./user-subscription.component.html",
	styleUrls: ["./user-subscription.component.scss"],
	standalone: true,
	imports: [NgIf, MatButton, DatePipe],
})
export class UserSubscriptionComponent implements OnInit {
	activeSubscription: Subscription = null;

	constructor(
		private http: MitpClientService,
		private manager: MitpManagerService,
		private dialog: MatDialog,
		private toastr: ToastrService,
		private route: ActivatedRoute,
		private router: Router
	) {}

	ngOnInit(): void {
		if (this.route.snapshot.queryParams["paymentResult"] != undefined) {
			const result = this.route.snapshot.queryParams["paymentResult"];
			switch (result) {
				case "failed":
					this.dialog
						.open(SubscriptionFailedComponent)
						.afterClosed()
						.subscribe(() => {
							this.clearParams();
						});
					break;
				case "successed":
					this.dialog
						.open(SubscriptionSuccessedComponent)
						.afterClosed()
						.subscribe(() => {
							this.clearParams();
						});
					break;
			}
		}
		this.http.getActiveSubscription().subscribe((subscription) => {
			this.activeSubscription = subscription;
		});
	}

	clearParams(): void {
		this.router.navigate([], {
			queryParams: {
				paymentResult: null,
			},
			queryParamsHandling: "merge",
		});
	}

	subscribe(): void {
		this.dialog
			.open(SubscriptionProcessComponent, {
				minWidth: "600px",
				disableClose: true,
			})
			.afterClosed()
			.subscribe((subscriptionResult) => {
				if (subscriptionResult) {
					/* empty */
				} else {
					this.toastr.info("Aucune modification n'a été effectuée.");
				}
			});
	}

	openPortal(): void {
		this.http.getPortalUrl().subscribe((url) => {
			if (url["url"] == "") {
				this.manager.openInfoDialog(
					"Votre portail sera créé automatiquement après votre abonnement à l'une de nos offres."
				);
			} else {
				window.location.href = url["url"];
			}
		});
	}
}
