import { Injectable } from "@angular/core";
import { JwtHelperService } from "@auth0/angular-jwt";
import { MatDialog } from "@angular/material/dialog";
import { LegalUpdateModalComponent } from "../modules/legal/legal-update-modal/legal-update-modal.component";
import { HttpClient } from "@angular/common/http";
import { MITPService } from "./mitp.service";
import { first, Observable } from "rxjs";

@Injectable({
	providedIn: "root",
})
export class LegalService {
	constructor(
		private jwtHelper: JwtHelperService,
		private dialog: MatDialog,
		private httpClient: HttpClient,
		private mitp: MITPService
	) {}

	requestCguNotice() {
		this.isCguUptoDate()
			.pipe(first())
			.subscribe((res) => {
				if (!res.cgu) {
					this.showCguNotice();
				}
			});
	}

	showCguNotice() {
		const legalDialog = this.dialog.open(LegalUpdateModalComponent, {
			width: "550px",
			autoFocus: false,
		});

		legalDialog.afterClosed().subscribe(() => {
			legalDialog.close();
			this.updateCgu().pipe(first()).subscribe();
		});
	}

	private updateCgu(): Observable<null> {
		return this.httpClient.put<null>(this.mitp.Users() + "/cgu", null);
	}

	private isCguUptoDate(): Observable<{ cgu: boolean }> {
		return this.httpClient.get<{ cgu: boolean }>(this.mitp.Users() + "/cgu");
	}
}
