import { IMPORT, LAYER, COMMENT, RULESET, DECLARATION, KEYFRAMES } from './Enum.js';
import { strlen } from './Utility.js';

/**
 * @param {object[]} children
 * @param {function} callback
 * @return {string}
 */
export function serialize(children, callback) {
  var output = '';
  for (var i = 0; i < children.length; i++) output += callback(children[i], i, children, callback) || '';
  return output;
}

/**
 * @param {object} element
 * @param {number} index
 * @param {object[]} children
 * @param {function} callback
 * @return {string}
 */
export function stringify(element, index, children, callback) {
  switch (element.type) {
    case LAYER:
      if (element.children.length) break;
    case IMPORT:
    case DECLARATION:
      return element.return = element.return || element.value;
    case COMMENT:
      return '';
    case KEYFRAMES:
      return element.return = element.value + '{' + serialize(element.children, callback) + '}';
    case RULESET:
      if (!strlen(element.value = element.props.join(','))) return '';
  }
  return strlen(children = serialize(element.children, callback)) ? element.return = element.value + '{' + children + '}' : '';
}